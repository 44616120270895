import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { uuidv4 } from '../util/apiUtil'
import authentication from './authentication'

export const cycledEnergyApi = createApi({
  reducerPath: 'cycledEnergyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${ENV_VAR_BFF_HOST}/api/v1`,
    prepareHeaders: async (headers) => {
      const accessToken = await authentication.getValidAccessToken()
      if (accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`)
      }
      headers.set('X-Correlation-ID', uuidv4())
      headers.set('X-Client', ENV_VAR_X_CLIENT)
      headers.set('content-type', 'application/json')
      return headers
    }
  }),
  endpoints: (builder) => ({
    getCycledEnergyData: builder.query({
      query: ({ externalEquipmentReference }) => `/energy?externalEquipmentReference=${externalEquipmentReference}`
    })
  })
})

export const { useLazyGetCycledEnergyDataQuery } = cycledEnergyApi

import React from 'react'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle as infoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'

import { useLazyGetCycledEnergyDataQuery } from '../../api/cycledEnergy'
import GAEvent from '../GAEvent'
import i18n from '../../util/localization'
import Spinner from '../Spinner'
import Style from './CycledEnergyPopup.scss'

const detailsPopover = ({ isLoading, isError, isSuccess, data, vehicleName, ReactGA, ...props }: any) => {
  return (
    <Popover
      id="cycled-energy-popover"
      className={'scania-popover hidden-sm hidden-xs'}
      {...props}
    >
      <GAEvent
    category="click"
    action="cycled energy popup clicked"
    ReactGA={ReactGA}
  />
      {isLoading && (<div className={Style.popupSpinnerContainer}>
        <Spinner />
      </div>)}
      {isError && <div>{i18n.t('EttFelHarUppstått_FörsökIgenSenare_')}</div>}
      {isSuccess && data.length > 0 && (
        <>
        <h4>{i18n.t('CykladEnergi')} {vehicleName}</h4>
          <div className={Style.individualDetail}>
            <span className={Style.subheader}>{Math.round(Number(data[0].value[0]))} {data[0].unit}</span>
          </div>
          <div className={Style.individualDetail}>
            {i18n.t('SammanlagdCykladEnergi_')}
          </div>
          <div className={Style.individualDetail}>
            {i18n.t('SenastUtläst_')} {moment(data[0].period_label).format('L')}
          </div>
        </>
      )}
      {isSuccess && data.length === 0 && <div>{i18n.t('DetFinnsIngetTillgängligtData')}</div>}
    </Popover>
  )
}

const CycledEnergyPopup = ({ details, ReactGA }: any) => {
  const [trigger, { isLoading, isError, isSuccess, data }] = useLazyGetCycledEnergyDataQuery()

  const handlePopupClick = () => {
    trigger({ externalEquipmentReference: details.externalEquipmentReference }, true)
  }

  return (
      <OverlayTrigger
        trigger='click'
        placement='right'
        shouldUpdatePosition
        overlay={detailsPopover({
          isLoading,
          isError,
          isSuccess,
          data,
          vehicleName: details.vehicleName,
          ReactGA
        })}
        className={`col-sm-12 hidden-xs hidden-sm ${Style.specModalIcon}`}
        >
          <FontAwesomeIcon
          className={`fa-lg ${Style.popupIcon}`}
          icon={infoCircle}
          onClick={handlePopupClick}
        />
      </OverlayTrigger>
  )
}

export default CycledEnergyPopup
